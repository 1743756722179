import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ConstructionImage from "../components/image_construction"

const ConstructionPage = () => (

  <Layout>
    <SEO title="Construction" />

      <div className="big-pictures">
        <ConstructionImage />
      </div>

  </Layout>
)

export default ConstructionPage
